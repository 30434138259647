@import "../../app";

body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
}

.page-404 {
    width: 100%;
    padding: 120px 0;
    text-align: center;

    @media (max-width: $mob) {
        padding: 70px 0;
    }

    &__picture {
        display: block;
        margin-bottom: 15px;
        text-align: center;
    }

    &__title {
        margin-bottom: 15px;
    }

    &__solutions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;

        @media (max-width: $tab) {
            flex-direction: column;
            margin-top: 75px;
        }
    }

    &__solution {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 500px;
        flex-shrink: 0;
        padding: 25px 30px;
        border-radius: 5px;
        text-align: left;

        @media (max-width: 1300px) {
            max-width: 400px;
        }

        @media (max-width: $tab) {
            max-width: 500px;
        }

        @media (max-width: $mob480) {
            padding: 10px 8px 30px;
        }

        &--sb {
            flex-direction: row-reverse;
            background: $second-brand-color;

            .page-404__solution-image {
                right: 40px;
                top: -60px;

                @media (max-width: 1300px) {
                    right: 20px;
                }

                @media (max-width: $mob) {
                    right: 10px;
                }
            }

            .page-404__solution-label {
                right: -35px;

                @media (max-width: $mob) {
                    right: -20px;
                }
            }
        }

        &--sm {
            background: $category-events;

            .page-404__solution-image {
                left: 30px;
                top: -60px;

                @media (max-width: 1300px) {
                    left: 0px;
                }

                @media (max-width: $mob) {
                    left: 0;
                }
            }

            .page-404__solution-label {
                left: -65px;

                @media (max-width: $mob) {
                    left: -20px;
                }
            }
        }
    }

    &__solution-image {
        position: absolute;
        max-width: 240px;

        @media (max-width: 1300px) {
            max-width: 200px;
        }

        @media (max-width: $mob480) {
            max-width: 170px;
        }
    }

    &__solution-content {
        width: 100%;
        max-width: 200px;

        @media (max-width: $mob480) {
            max-width: 160px;
        }
    }

    &__solution-title {
        margin-bottom: 15px;

        @media (max-width: $mob480) {
            margin-bottom: 8px;
            font-size: 16px;
        }
    }

    &__solution-text {
        margin-bottom: 25px;

        @media (max-width: $mob) {
            margin-bottom: 15px;
            font-size: 14px;
        }
    }

    &__solution-label {
        position: absolute;
        display: flex;
        max-height: 50px;
        bottom: 25px;
        padding: 13px 30px;
        border-radius: 5px;
        box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
        background: $white;

        @media (max-width: $mob480) {
            max-width: 140px;
            max-height: 35px;
            padding: 10px 22px;
            bottom: 35px;
        }
    }

    &__solutions-divider {
        display: flex;
        align-items: center;
        margin: 0 15px;
        font-size: 22px;
        font-weight: 300;
        text-transform: uppercase;

        @media (max-width: $tab) {
            margin: 40px 0 55px;
        }

        &:before {
            margin-right: 15px;
        }

        &:after {
            margin-left: 15px;
        }

        &:before, &:after {
            display: flex;
            content: '';
            width: 78px;
            height: 1px;
            background: #E7ECF7;

            @media (max-width: 1300px) {
                width: 50px;
            }
        }
    }
}

#footer{
    width: 100%;
}
