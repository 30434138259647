@import "../../scss/base/normalize";
@import "../../scss/base/reset-css";
@import "/base/vars";
@import "../../scss/base/ui-kit";
@import "../simplymeet/base/ui-kit-button";
@import "../../scss/base/components/ui-components/index";
@import "../../scss/base/main";
@import "../../scss/base/grid";
@import "../../scss/base/font-utils";
@import "../../scss/base/form-validation";
@import "base/components/faq-section";

// UI Kit edits
.btn {
  &--primary {
    color: $dark-blue;

    &:hover {
      color: $dark-blue;
    }
  }
}

// Header language
//.header__language {
//  display: none;
//}

// Features not found shape
.features-not-found:before {
  background: #fff8ea !important;
}

//Import base fonts
$font-path: '/build/fonts/';
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold", 700, inherit, eot woff2 woff ttf otf svg);
@include font-face("Noto Sans", "#{$font-path}noto-sans/NotoSans-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Caveat", "#{$font-path}caveat/Caveat-Bold", 700, inherit, eot woff2 woff ttf otf svg);

// Modules from header & footer
@import "base/components/header";
@import "base/components/footer";
@import "base/components/main-menu";
@import "base/components/cookie";
@import "../../scss/modules/header-and-footer/language-selector";
@import "../../scss/modules/header-and-footer/liveChatCard";
@import "../../scss/base/components/popup--login-form";