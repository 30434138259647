.cookies{
  //display: none;
  position: fixed;
  bottom: 75px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  z-index: 1000;

  @include break-max(900px){
    border-radius: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__cover{
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($dark-blue, 0.2);
    z-index: -1;
  }

  &__container{
    position: relative;
    padding: 34px 45px;
    border-radius: 20px;
    background-color: #FFF8EA;
    box-shadow: 0px -5px 15px -14px rgba(37, 50, 70,0.1);
    z-index: 2;

    @include break-max(900px){
      padding: 30px 2px;
    }
  }

  &__close-btn{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    border: none;
    background: rgba(#fff, 0.2);
    backdrop-filter: blur(8px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    svg{
      height: 16px;
      path{
        fill: $category-beauty-hover;
      }
    }

    &:hover, &:focus{
      background: rgba($dark-blue, 0.02);
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;

    @include break-max($tab){
      flex-direction: column;
      text-align: center;
    }
  }

  &__card-data{
    width: 100%;
  }

  &__card-content {
    display: flex;
    flex-direction: column;

    @include break-max($tab){
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  &__card-image {
    min-width: 220px;
    width: 220px;
    position: relative;
    min-height: 80px;
    margin: -16px 50px -16px 0;
    align-self: center;

    @include break-max($tab) {
      display: none;
    }
  }

  &__card-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 30px;
    line-height: 1;
    color: $dark-blue;

    @include break-max($tab) {
      justify-content: center;
      font-size: 24px;
    }
  }

  &__card-text {
    @include break-max($tab) {
      max-width: 600px;
      text-align: center;
      font-size: 14px;
    }
  }

  &__card-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px 8px;
    margin: 12px 0 0;

    @include break-max($tab) {
      justify-content: center;
    }
  }

  &__card-btn {
    min-width: 140px;
    justify-content: center;
    margin: 0;
    font-weight: 500;
    padding: 6px 24px;

    @include break-max($mob) {
      min-width: 50px;
      min-height: 36px;
    }
  }

  &.cookies__footer-banner{
    bottom: 48px;

    .cookies{
      &__cover{
       display: none;
      }

      &__container{
        padding: 18px 2px;
        border-radius: 12px;
      }

      &__close-btn{
        display: none;
      }

      &__card-data{
        @include break-min($tab){
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px 10px;
        }
      }

      &__card-image {
        display: none;
      }

      &__card-title {
        font-size: 24px;
      }

      &__card-btns {
        @include break-min($tab){
          justify-content: flex-end;
          margin: 0;
        }
      }

      &__card-btn {
        min-height: 36px;
        padding: 6px 25px;
      }
    }
  }
}