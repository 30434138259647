.faq-section{
	padding: 60px 0;
	@include break-min($tab){
		padding: 80px 0;
	}

	.masonry-grid{
		padding-top: 10px;
		margin: 0 auto;
		width: 100%;
		max-width: 860px;
		display: flex;
		flex-direction: column;
		gap: 22px;
		align-items: stretch;

		&--item{
			border: 2px solid #E7ECF7;
			border-radius: 8px;

			&-header{
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 20px;
				min-height: 88px;
				padding: 0 0 0 20px;
				@include break-max(800px){
					align-items: flex-start;
				}
				@include break-max($mob){
					padding: 0 0 0 10px;
					gap: 10px;
				}

				.title{
					margin: 0;
					padding: 0;
					flex: 1;
					display: inline-flex;
					align-items: center;
					justify-content: flex-start;
					min-height: 84px;
					@include break-max(800px){
						font-size: 18px;
					}
					@include break-max($mob){
						font-size: 16px;
					}
				}
			}
			&-index{
				font-family: $mulish;
				font-size: 20px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				min-height: 88px;
				color: $dark-blue;
				font-weight: bolder;
				min-width: 22px;

				@include break-max($mob){
					display: none;
				}
			}
			&-toggler{
				position: relative;
				aspect-ratio: 1/1;
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 90px;
				background: $dark-blue-bg;
				cursor: pointer;
				border-radius: 0 8px 8px 0;
				color: #fff;
				font-size: 30px;
				margin: -2px;

				span{
					&:before{
						display: inline-block;
						content: '+';
					}
				}

				&.--active{
					background: $primary-sm;
					span{
						&:before{
							content: '-';
						}
					}
				}
			}
			&-body{
				display: none;
				padding: 12px 20px 18px 64px;

				b{
					font-family: $mulish;
					font-weight: bold;
					color: $dark-blue;
				}

				.masonry-grid--item_list-num{
					margin-bottom: 16px;
					padding-left: 1em;
					li{
						padding-left: 4px;
						list-style: decimal;
					}
				}

				.masonry-grid--item_list-dots{
					margin-bottom: 16px;
					padding-left: 1em;
					li{
						list-style: disc;
					}
				}

				.p--secondary{
					margin-bottom: 18px;
					&:last-child{
						margin-bottom: 0;
					}
				}

				.link{
					position: relative;
					display: inline;
					align-items: center;
					justify-content: flex-start;
					padding: 4px 20px 4px 0;
					margin: 2px 0;
					gap: 6px;
					text-align: left;

					svg{
						position: absolute;
						bottom: 0.4em;
						right: 0;
						width: 14px;
						height: 16px;
					}
				}

				@include break-max($mob){
					padding: 20px;
				}
			}
		}

		&.--grid{
			.masonry-grid--item-body{display: block;}
		}
	}
}