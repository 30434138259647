.btn {
	&.btn--primary{
		background: $primary-sm;
		color: $dark-blue;
		&:hover{
			background: darken($primary-sm, 10%);
			color: $dark-blue;
		}
	}
}

.link{
	&.p-1{
		padding-left: 4px;
		padding-right: 4px;
	}
}