.footer {
  padding: 60px 0 50px;
  border-top: 1px solid $second-brand-color;

  &__nav {
    &-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 80px;

      @media (max-width: $tab) {
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -10px 80px;
      }

      @media (max-width: $mob) {
        margin: 0 -10px 60px;
      }
    }

    &-column {
      @media (max-width: $tab) {
        max-width: calc(33.333% - 20px);
        width: 100%;
        margin: 0 10px 30px;
      }

      @media (max-width: $mob) {
        max-width: calc(50% - 20px);
        margin: 0 10px 50px;

        &:last-child,
        &:nth-last-child(2) {
          margin-bottom: 0;
        }

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 2;
        }

        &:nth-child(4) {
          order: 4;
        }

        &:nth-child(5) {
          order: 5;
        }

        &:nth-child(6) {
          order: 6;
        }
      }
    }

    &-title {
      margin-bottom: 10px;
    }

    &-item {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &--has-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
      }
    }

    &-link {
      font-size: $small-text;
      text-decoration: none;
      @include transition;

      &:hover {
        color: $primary-blue;
        text-decoration: none;
      }
    }
  }

  &__social {
    margin-bottom: 45px;
    padding: 25px 0;
    border-top: 1px solid $second-brand-color;
    border-bottom: 1px solid $second-brand-color;

    @media (max-width: $mob) {
      margin-bottom: 30px;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 200px;
      margin: 0 auto;
    }

    &-link {
      display: flex;
      color: $social-link-color;
      text-decoration: none;

      &:hover{
        color: darken($social-link-color, 6%);
      }

      svg {
        height: 22px;
      }
    }
  }

  &__bottom {
    text-align: center;

    &-logo {
      display: flex;
      max-width: 190px;
      margin: 0 auto 25px;
    }

    &-copyrights {
      margin-bottom: 5px;

      &--small {
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}