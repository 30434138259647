header.header{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0 20px;
  @include break-max($tab){
    padding: 0;
  }

  .header--settings-block{
    position: fixed;
    width: 100%;
    height: 65px;
    top: 0;
    left: 0;
    border-bottom: 1px solid $second-brand-color;
    background: $white;
    z-index: 10;

    @include break-max($mob){
      width: 100%;
      position: relative;
      height: 50px;
    }

    .header--settings-container{
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all 1s ease;

      @include break-max($mob){
        height: 50px;
      }

      .header--togg-menu{
        display: none;

        @include break-max($mob){
          display: inline-flex;
          margin-right: 15px;
          top: 0;
          height: 15px;
        }
      }
    }

    .mobile-header--shape{
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      z-index: -1;

      @include break-max($mob){
        display: block;
      }

      svg{
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-filter: drop-shadow(0px 13px 12px rgba(86, 153, 196, 0.05));
        filter: drop-shadow(0px 13px 12px rgba(86, 153, 196, 0.05));

        path{
          fill: #fff;
        }
      }
    }
  }

  .header--logo{
    margin-right: 15px;

    @include break-min($mob) {
      width: 100%;
      max-width: 185px;
      overflow: hidden;
      @include transition;

      &.is-scrolled {
        max-width: 45px;
      }
    }

    @include break-max($mob){
      width: 100%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .header--logo-image{
      display: block;
      width: 185px;

      &__winter{
        height: 34px;
      }

      @include break-max($mob){
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }

  .header--navigation-block {
    display: block;
    position: absolute;
    left: 0;
    top: 65px;
    background: transparent;
    width: 100%;

    .header--navigation-menu-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: 65px;
      padding-top: 15px;
      padding-bottom: 15px;

      @include break-max($mob){
        min-height: unset;
        padding-top: 0;
        padding-bottom: 0;
      }

      //.header--togg-menu{
      //  margin-right: 30px;
      //
      //  @include break-max($tab){
      //    display: none;
      //  }
      //}

      .main-menu-short{
        position: fixed;
        top: 85px;
        display: inline-block;
        width: auto;
        @include transition;
        z-index: 10;

        &.is-scrolled {
          top: 18px;

          .menu--link-label {
            display: none;
          }
        }

        @include break-max(1200px) {
          position: static;
          top: unset;
          z-index: 1;
        }

        @include break-max($mob){
          display: none;
        }
      }

      .main-menu-short .menu--link{
        position: relative;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        height: 30px;
        margin: 0 40px 0 0;
        text-decoration: none;
        color: $dark-blue;
        transition: color 0.2s ease-in;
        line-height: 1;

        &:last-child{
          margin-right: 0;
        }

        &:hover{
          color: darken($dark-blue, 6%);
        }

        &.active{
          color: darken($dark-blue, 8%);
        }

        &--has-label {
          position: relative;
        }

        .menu--link-label {
          position: absolute;
          top: 100%;
          width: 100%;
          min-width: 75px;
          text-align: center;
          left: 0;
        }

        .menu__dropdown {
          display: none;
          position: absolute;
          min-width: 200px;
          top: 35px;
          left: -30px;
          padding: 25px 0;
          border-radius: 10px;
          box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
          background: $white;

          &.opened {
            display: block;
          }

          &-item {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &-item-link {
            display: flex;
            align-items: center;
            padding: 0 40px 0 30px;

            &:hover,
            &.active {
              color: $dark-blue;

              svg path {
                fill:  $dark-blue;
              }
            }
          }

          &-item-icon {
            width: 16px;
            margin-top: -2px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  &.header--stickly{

    .header--settings-block{
      .header--togg-menu{
        @include break-max($mob){
          display: inline-flex;
          position: fixed;
          top: 15px;
          left: 15px;
          height: 50px;
          width: 50px;
          background: rgba($second-brand-color, 0.8);
          border-radius: 50%;
          justify-content: center;
          z-index: 100;
          transition: top 1s ease;
          mix-blend-mode: multiply;
        }
      }
    }

    .header--navigation-block{
      .header--navigation-menu-container{
        min-height: unset;

        .header--togg-menu,
        .main-menu-short{

        }
      }
    }
  }

  &.main-menu-short-white{
    .header--navigation-block {
      .header--navigation-menu-container {
        .main-menu-short .menu--link{
          color: $white;

          &:last-child{
            padding-right: 0;
          }

          &:hover{
            color: darken($white, 6%);
          }

          &.active{
            color: darken($white, 8%);
          }
        }
      }
    }
  }
}

.header--user-block{
  display: flex;
  margin-left: auto;
  margin-right: 0;

  .open-sub-menu-login-bar{
    display: none;
    width: 30px;
    height: 40px;
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    box-shadow: none;
    outline: none;

    @include break-max($mob){
      display: inline-flex;
    }

    &:hover, &:active{
      border: none;
      background: transparent;
      box-shadow: none;
      outline: none;
    }

    .icon{
      //font-weight: bold;
      //font-size: 20px;
      color: $primary-blue;
      width: 20px;
      height: 20px;
    }
  }

  .btn{
    margin: 4px 10px;
    font-weight: 600;
    min-height: 40px;

    &.btn--login{
      padding: 0 5px;
    }

    @include break-max($mob){
      margin: 0 0 0 4px;
    }
  }

  .hide-on-mob{
    @include break-max($mob){
      display: none !important;
    }
  }
}

.header--mob-togg-menu,
.header--togg-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  height: 30px;
  color: $dark-blue;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}

.header--mob-togg-menu .hamburger-box,
.header--togg-menu .hamburger-box{
  display: inline-block;
  position: relative;
  width: 23px;
  height: 15px;
  transition: .4s ease-in-out;
  margin-right: 15px;
  z-index: 1;

  @include break-max($mob){
    margin-right: 0;
    //width: 26px;
  }
}

.header--mob-togg-menu .txt-box,
.header--togg-menu .txt-box{
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;

  @include break-max($mob){
    display: none;
  }
}

.header--mob-togg-menu .hamburger-box span,
.header--togg-menu .hamburger-box span{
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: $dark-blue;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .35s ease-in-out;
}

.header--mob-togg-menu .hamburger-box span:nth-child(1),
.header--togg-menu .hamburger-box span:nth-child(1) {
  top: 0;
}

.header--mob-togg-menu .hamburger-box span:nth-child(2),
.header--togg-menu .hamburger-box span:nth-child(2) {
  width: 100%;
  top: 7px;
}
.header--mob-togg-menu:hover .hamburger-box span:nth-child(2),
.header--togg-menu:hover .hamburger-box span:nth-child(2) {
  left: -10%;
}

.header--mob-togg-menu .hamburger-box span:nth-child(3),
.header--togg-menu .hamburger-box span:nth-child(3) {
  top: 15px;
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(1),
.header--togg-menu.open .hamburger-box span:nth-child(1) {
  top: 12px;
  left: 5px;
  width: 80%;
  background: $primary-red;
  transform: rotate(135deg);

  @include break-max($mob){
    left: 3px;
  }
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(2),
.header--togg-menu.open .hamburger-box span:nth-child(2) {
  left: -60px;
  opacity: 0;
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(3),
.header--togg-menu.open .hamburger-box span:nth-child(3) {
  top: 12px;
  left: 5px;
  width: 80%;
  background: $primary-red;
  transform: rotate(-135deg);

  @include break-max($mob){
    left: 3px;
  }
}